import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProTip from '@components/ProTip';
import Link from '@components/Link';
import Copyright from '@components/Copyright';
import { StaticImage } from 'gatsby-plugin-image';

const Index: React.FC = () => {
  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 9 }}>
      <StaticImage
        src="../assets/images/icon.png"
        // width={300}
        height={210}
        quality={95}
        formats={['auto', 'webp', 'avif']}
        alt="aoron logo"
        // style={{ marginBottom: '1.45rem' }}
      />
    </Container>
  );
};

export default Index;
